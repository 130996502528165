module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Queen Decor","short_name":"Queen Decor","description":"Создаем уникальный декор для уюта детской комнаты.","lang":"ru","display":"standalone","icon":"src/assets/images/favicon.svg","icon_options":{"purpose":"maskable"},"start_url":"/","background_color":"#D1BAA8","theme_color":"#D1BAA8","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28f91647d338f7b1cc28972bcb5b1d3b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":74734219,"webvisor":true,"trackHash":true,"afterBody":true,"defer":false,"useCDN":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
